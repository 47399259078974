import axios from "axios";
import React from "react";

const basicurl = " https://production-backend.kast.biz/"

export const GreenTheme = () => {

    const [leadType, setLeadType] = React.useState("");
    const [field, setFieldValue] = React.useState({
        "name": "",
        "email": "",
        "phone": "",
        "lead_type": ""
    })

    const leadOptions = [
        "Divorce Leads",
        "Absentee Landlords",
        "Distressed Homeowner",
        "FSBO",
    ];


    const setField = (key, val) => {
        setFieldValue({ ...field, [key]: val });
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log(field);

        const resp = await axios.post(basicurl + "api/saveLandingPageContact", field)
        console.log(resp);
    }
    return <>
        <div class="preloader">
            <span class="loader"> </span>
        </div>

        <header id="stickyHeader" className="bg-light">
            <div class="container">
                <div class="top-bar">
                    <div class="logo">
                        <a href="#">
                            <img alt="logo" src="/greenTheme/assets/img/logo.png" />
                        </a>
                    </div>
                    <nav>
                        <ul>
                            <li><a href="#home">home</a></li>
                            <li><a href="#about">About</a></li>
                            <li><a href="#services">Services</a></li>
                            <li><a href="#team">Team</a></li>
                            <li><a href="#pricing">Pricing</a></li>
                            <li><a href="#faqs">Faq’s</a></li>
                        </ul>
                    </nav>
                    <div class="login">
                        <a href="#">login</a>
                        <a href="#" class="btn"><span>sign up</span></a>
                    </div>
                </div>
            </div>
        </header>


        <section style={{ height: "initial" }} id="home" class="hero-section bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="hero-text">
                            <h1>Get 10 High-Quality Seller Leads in Your Area</h1>
                            <p>Choose from exclusive homeowner data—including Divorce, Distressed, Absentee Landlords, and FSBO leads—to help you close more deals.</p>
                            <a href="#" class="btn"><span>Get Started</span></a>
                            <div class="rating">
                                <h4>A+ Rating</h4>
                                <h5>4.9 OUT OF 5 rating</h5>
                                <ul class="stars">
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        {/* method="post" */}
                        <form role="form" class="get-a-quote" id="contact-form" >
                            <div>
                                <h3>Get a Quote</h3>
                                <h6>Marketing Business campaign</h6>
                            </div>
                            <div class="group-img">
                                <i><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_22_21)">
                                        <path d="M13.6569 10.3431C12.7855 9.47181 11.7484 8.82678 10.6168 8.43631C11.8288 7.60159 12.625 6.20463 12.625 4.625C12.625 2.07478 10.5502 0 8 0C5.44978 0 3.375 2.07478 3.375 4.625C3.375 6.20463 4.17122 7.60159 5.38319 8.43631C4.25163 8.82678 3.2145 9.47181 2.34316 10.3431C0.832156 11.8542 0 13.8631 0 16H1.25C1.25 12.278 4.27803 9.25 8 9.25C11.722 9.25 14.75 12.278 14.75 16H16C16 13.8631 15.1678 11.8542 13.6569 10.3431ZM8 8C6.13903 8 4.625 6.486 4.625 4.625C4.625 2.764 6.13903 1.25 8 1.25C9.86097 1.25 11.375 2.764 11.375 4.625C11.375 6.486 9.86097 8 8 8Z" fill="black" />
                                    </g>
                                </svg></i>
                                <input type="text" onChange={(e) => { setField("name", e.target.value) }} name="Full Name" placeholder="Full Name" required="" />
                            </div>
                            <div class="group-img">
                                <i><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_22_25)">
                                        <path d="M17.0769 3H0.923098C0.415371 3 0 3.41537 0 3.92306V14.0769C0 14.5846 0.415371 15 0.923098 15H17.0769C17.5846 15 18 14.5847 18 14.0769V3.92306C18 3.41537 17.5846 3 17.0769 3ZM16.7305 3.69226L9.531 9.09233C9.40156 9.19084 9.20285 9.25247 8.99996 9.25155C8.79711 9.25247 8.59845 9.19084 8.46896 9.09233L1.26946 3.69226H16.7305ZM12.8848 9.44864L16.8079 14.2948C16.8118 14.2997 16.8166 14.3034 16.8208 14.3078H1.17921C1.18336 14.3032 1.18821 14.2997 1.19215 14.2948L5.11523 9.44864C5.23543 9.30003 5.21265 9.08217 5.06377 8.96169C4.91516 8.84149 4.6973 8.86427 4.57706 9.01291L0.692297 13.8118V4.12496L8.0538 9.64611C8.33052 9.8522 8.66718 9.9429 8.99993 9.94382C9.33223 9.94311 9.66916 9.85241 9.94605 9.64611L17.3076 4.12496V13.8117L13.4229 9.01291C13.3027 8.86431 13.0846 8.84146 12.9362 8.96169C12.7873 9.08189 12.7645 9.30003 12.8848 9.44864Z" fill="black" />
                                    </g>
                                </svg>
                                </i>
                                <input type="email" onChange={(e) => { setField("email", e.target.value) }} name="Email Address" placeholder="Email Address" required="" />
                            </div>
                            <div class="group-img">
                                <i>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_22_29)">
                                            <path d="M13.9786 0H6.99136C5.75827 0.00139826 4.75904 1.00063 4.75781 2.23372V18.7664C4.75904 19.9995 5.75827 20.9987 6.99136 20.9999H13.9786C15.2117 20.9987 16.211 19.9995 16.2124 18.7664V2.23372C16.211 1.00063 15.2117 0.00139826 13.9786 0ZM15.2577 18.7664C15.257 19.4725 14.6848 20.0448 13.9786 20.0455H6.99136C6.28524 20.0448 5.713 19.4725 5.7123 18.7664V2.23372C5.713 1.52742 6.28524 0.955186 6.99136 0.954487H13.9786C14.6848 0.955186 15.257 1.52742 15.2577 2.23372V18.7664Z" fill="black" />
                                            <path d="M11.917 1.90918H9.05338C8.78981 1.90918 8.57605 2.12276 8.57605 2.38634C8.57605 2.64991 8.78981 2.86367 9.05338 2.86367H11.917C12.1806 2.86367 12.3942 2.64991 12.3942 2.38634C12.3942 2.12276 12.1806 1.90918 11.917 1.90918Z" fill="black" />
                                            <path d="M11.4397 18.1363C11.4397 18.6636 11.0123 19.091 10.4852 19.091C9.95786 19.091 9.53052 18.6636 9.53052 18.1363C9.53052 17.6092 9.95786 17.1818 10.4852 17.1818C11.0123 17.1818 11.4397 17.6092 11.4397 18.1363Z" fill="black" />
                                        </g>
                                    </svg>
                                </i>
                                <input type="number" onChange={(e) => { setField("phone", e.target.value) }} name="Phone Number" placeholder="Phone Number" required="" />
                            </div>
                            <div class="group-img">
                                <i>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_22_29)">
                                            <path d="M13.9786 0H6.99136C5.75827 0.00139826 4.75904 1.00063 4.75781 2.23372V18.7664C4.75904 19.9995 5.75827 20.9987 6.99136 20.9999H13.9786C15.2117 20.9987 16.211 19.9995 16.2124 18.7664V2.23372C16.211 1.00063 15.2117 0.00139826 13.9786 0ZM15.2577 18.7664C15.257 19.4725 14.6848 20.0448 13.9786 20.0455H6.99136C6.28524 20.0448 5.713 19.4725 5.7123 18.7664V2.23372C5.713 1.52742 6.28524 0.955186 6.99136 0.954487H13.9786C14.6848 0.955186 15.257 1.52742 15.2577 2.23372V18.7664Z" fill="black" />
                                            <path d="M11.917 1.90918H9.05338C8.78981 1.90918 8.57605 2.12276 8.57605 2.38634C8.57605 2.64991 8.78981 2.86367 9.05338 2.86367H11.917C12.1806 2.86367 12.3942 2.64991 12.3942 2.38634C12.3942 2.12276 12.1806 1.90918 11.917 1.90918Z" fill="black" />
                                            <path d="M11.4397 18.1363C11.4397 18.6636 11.0123 19.091 10.4852 19.091C9.95786 19.091 9.53052 18.6636 9.53052 18.1363C9.53052 17.6092 9.95786 17.1818 10.4852 17.1818C11.0123 17.1818 11.4397 17.6092 11.4397 18.1363Z" fill="black" />
                                        </g>
                                    </svg>
                                </i>
                                <input type="number" onChange={(e) => { setField("phone", e.target.value) }} name="Preferred Area (City/Zip)" placeholder="Preferred Area (City/Zip)" required="" />
                            </div>

                            <p>Select Lead Type: </p>
                            <div>
                                <input
                                    list="leadOptions"
                                    id="leadType"
                                    placeholder="select any option"
                                    name="leadType"
                                    value={leadType}
                                    onChange={(e) => setLeadType(e.target.value)}
                                    className="form-control"
                                />
                                <datalist id="leadOptions">
                                    {leadOptions.map((option, index) => (
                                        <option key={index} value={option} />
                                    ))}
                                </datalist>
                            </div>
                            <div className="w-100 text-center my-2">
                                <span className="text-secondary text-center" style={{ fontSize: 14, }}>We’ll confirm your lead preferences in a quick 15-min call before delivery.</span>
                            </div>

                            <button type="submit" onClick={handleFormSubmit} class="btn text-center w-100"><span>Claim 10 Seller Leads Now</span></button>
                        </form>
                    </div>
                </div>
            </div>
            <img alt="img" class="shaps-line" src="/greenTheme/assets/img/shaps-line.png" />
            <img alt="img" class="shaps-dots" src="/greenTheme/assets/img/shaps-dots.png" />
        </section>


        <section id="services" class="gap no-bottom" style={{ height: "initial", backgroundImage: "url(/greenTheme/assets/img/background_1.png)" }}>
            <div class="container">
                <div class="heading">
                    <h2>Choose the Type of Leads That Fit Your  <span>Strategy</span></h2>
                    {/* <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p> */}
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="service-text">
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 16 16"><g fill="currentColor"><path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" /><path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z" /></g></svg>
                            </i>
                            <a href="#"><h4>Divorce Leads</h4></a>
                            <p>Homeowners going through life changes are often motivated to sell quickly—giving proactive agents a unique opportunity to assist.</p>
                        </div>
                        <div class="service-text two">
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 16 16"><path fill="currentColor" d="M7.749 16c-.771 0-1.424-.225-1.939-.669c-.519-.447-.782-.969-.782-1.552a.98.98 0 0 1 .307-.726c.207-.202.465-.309.748-.309c.281 0 .534.1.732.29c.195.187.294.435.294.736q0 .267-.086.58a1.5 1.5 0 0 0-.068.364c0 .058.014.126.121.199c.199.138.439.204.732.204c.353 0 .667-.123.962-.375c.29-.249.431-.505.431-.782c0-.308-.082-.575-.252-.816c-.287-.402-.826-.874-1.603-1.401c-1.248-.835-2.079-1.559-2.54-2.211c-.358-.511-.539-1.061-.539-1.636c0-.579.19-1.155.564-1.713c.32-.477.794-.908 1.41-1.283q-.496-.534-.736-.995a2.6 2.6 0 0 1-.303-1.189c0-.747.295-1.393.878-1.92S7.39.001 8.241.001c.783 0 1.441.22 1.956.654c.521.439.785.952.785 1.524q0 .44-.324.776l-.004.004c-.125.124-.353.271-.735.271c-.299 0-.561-.098-.758-.283a.88.88 0 0 1-.296-.656c0-.108.027-.272.084-.515q.042-.173.042-.316a.48.48 0 0 0-.183-.39C8.679.962 8.494.909 8.243.909c-.389 0-.708.118-.975.361s-.399.533-.399.883q0 .474.212.771c.267.374.731.778 1.378 1.201c1.315.853 2.233 1.636 2.727 2.325c.365.518.549 1.068.549 1.637c0 .572-.186 1.148-.552 1.714c-.316.487-.793.926-1.42 1.308c.347.367.591.688.743.977c.189.359.284.751.284 1.165c0 .776-.296 1.435-.879 1.96s-1.31.79-2.161.79zM6.975 5.568c-.753.452-1.12.972-1.12 1.583c0 .356.102.674.31.973c.311.436.926.97 1.825 1.583q.572.39 1.025.751c.767-.461 1.14-.974 1.14-1.565q0-.485-.378-1.03c-.263-.378-.826-.872-1.674-1.467a21 21 0 0 1-1.128-.827z" /></svg>
                            </i>
                            <a href="#"><h4>Distressed Property Owners</h4></a>
                            <p>Identify financially distressed homeowners who may need to sell fast—perfect for agents specializing in quick sales and investor deals.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="video">
                            <a data-fancybox="" href="https://www.youtube.com/watch?v=xKxrkht7CpY">
                                <i>
                                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 8.49951L0.5 0.27227L0.5 16.7268L11 8.49951Z" fill="#000"></path>
                                    </svg>
                                </i>
                            </a>
                            <img src="https://placehold.co/510x690" alt="img" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="service-text three">
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="currentColor" d="m14.02 21.923l-7.616-2.22V21h-4v-8.154h6.373l7.358 2.693v1.615H21v2.615zM3.403 20h2v-6.154h-2zm10.565.862l6.008-1.831v-.877h-6.921L9.95 17.142l.33-.957l2.926.969h1.929v-.92l-6.516-2.388H6.404v4.808zM19 14.769v-7l-5.5-3.846L8 7.769v2.692H7V7.29l6.5-4.635L20 7.289v7.48zm-4.904-6.327h.808v-.808h-.808zm-2 0h.808v-.808h-.808zm2 2h.808v-.808h-.808zm-2 0h.808v-.808h-.808z" /></svg>
                            </i>
                            <a href="#"><h4>Absentee Landlords</h4></a>
                            <p>Connect with landlords managing properties from afar—often looking to offload rental properties quickly.</p>
                        </div>
                        <div class="service-text for">
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20"><path fill="currentColor" d="M2 3.5A1.5 1.5 0 0 1 4.915 3H16.5a1.5 1.5 0 0 1 .497 2.916L17 6v7a3 3 0 0 1-3 3H7a3 3 0 0 1-2-.764v2.014a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1-.75-.75zM16 13V6H5v7a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2M4 5h12.5a.5.5 0 0 0 0-1H4v-.5a.5.5 0 0 0-1 0V17h1zm3.5 4.955v2.55a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-2.55a1 1 0 0 0-.336-.748l-2.332-2.07a.5.5 0 0 0-.664 0l-2.332 2.07a1 1 0 0 0-.336.748" /></svg>
                            </i>
                            <a href="#"><h4>FSBO (For Sale By Owner)</h4></a>
                            <p>Reach homeowners trying to sell without an agent—show them how your expertise can help them get better results.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style={{ height: "initial" }} class="gap no-top provide bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="img-provide">
                            <img src="https://placehold.co/636x499" alt="img" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="heading two w-100">
                            <h2>Why Top Agents Trust Kast for  <span>High-Intent</span>  Seller Leads</h2>
                            {/* <p>Increase your efficiencies, and create a better experience for everyone involved. Automate your workflows with tools you use every day.</p> */}
                            <ul class="check-list">
                                <li><img src="/greenTheme/assets/img/check.png" alt="check" />Hyper-Targeted Leads – Focused on your preferred market</li>
                                <li><img src="/greenTheme/assets/img/check.png" alt="check" />Exclusive Data Access – Homeowner insights most agents miss</li>
                                <li><img src="/greenTheme/assets/img/check.png" alt="check" />First Mover Advantage – Reach sellers before your competition</li>
                                <li><img src="/greenTheme/assets/img/check.png" alt="check" />Designed for Agents – Tools built by real estate professionals</li>

                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div class="container gap no-bottom">
                    <div class="recommended">
                        <h5>Recommended by world-class companies</h5>
                    </div>
                    <div class="clients-slider owl-carousel">
                        <div class="item">
                            <img alt="clients" src="https://placehold.co/259x95" />
                        </div>
                        <div class="item">
                            <img alt="clients" src="https://placehold.co/259x95" />
                        </div>
                        <div class="item">
                            <img alt="clients" src="https://placehold.co/259x95" />
                        </div>
                        <div class="item">
                            <img alt="clients" src="https://placehold.co/259x95" />
                        </div>
                        <div class="item">
                            <img alt="clients" src="https://placehold.co/259x95" />
                        </div>
                    </div>
                </div> */}
            </div>
            <img alt="img" class="shaps-dots" src="/greenTheme/assets/img/shaps-dots.png" />
        </section>

        <section style={{ height: "initial" }} class="gap started bg-light">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-9">
                        <div class="heading two">
                            <h2>How to Get Your 10 High-Intent  <span>Seller Leads</span></h2>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-4 col-md-6">
                        <div class="consulting-business-text">
                            <span>1</span>
                            <h4>Submit Your Info.</h4>
                            <p>Fill out the form with your contact details and target area.</p>
                            <div class="consulting">
                                {/* <img alt="img" src="/greenTheme/assets/img/mail-1.png" /> */}

                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2"><path d="M4 7c0-1.886 0-2.828.586-3.414S6.114 3 8 3h8c1.886 0 2.828 0 3.414.586S20 5.114 20 7v8c0 2.828 0 4.243-.879 5.121C18.243 21 16.828 21 14 21h-4c-2.828 0-4.243 0-5.121-.879C4 19.243 4 17.828 4 15z" /><path stroke-linecap="round" d="M15 18v3m-6-3v3M9 8h6m-6 4h6" /></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="consulting-business-text two">
                            <span>2</span>
                            <h4>Quick 15-Min Call</h4>
                            <p>We’ll confirm your preferences to ensure you get the right leads.
                            </p>
                            <div class="consulting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="currentColor" d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79zm9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75zM7.5 3H4c-.55 0-1 .45-1 1c0 9.39 7.61 17 17 17c.55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1c-1.24 0-2.45-.2-3.57-.57a.8.8 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.15 15.15 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.4 11.4 0 0 1 8.5 4c0-.55-.45-1-1-1" /></svg>
                                {/* <img alt="img" src="/greenTheme/assets/img/presentation-1.png" /> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="consulting-business-text three">
                            <span>3</span>
                            <h4>Receive Your Leads</h4>
                            <p>Get your customized list of seller leads delivered to your inbox.
                            </p>
                            <div class="consulting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="currentColor" d="M4 19h4v-8H4zm6 0h4V5h-4zm6 0h4v-6h-4zM2 21V9h6V3h8v8h6v10z" /></svg>
                                {/* <img alt="img" src="/greenTheme/assets/img/card-payment-1.png" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img alt="img" class="shaps-dots" src="/greenTheme/assets/img/shaps-dots.png" />
        </section>








        <section style={{ height: "initial" }} id="faqs" class="gap accordion-section bg-light">
            <div class="container">
                <div class="heading two">
                    <h2>Frequently Asked  <span>Questions</span></h2>
                </div>
                <div class="row">
                    <div class="col-lg-5">
                        <div class="accordion-style">
                            <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p>
                            <div class="accordion">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"><i class="fa-solid fa-chevron-right"></i></div>
                                        <div class="title">Are these leads exclusive to me?</div>
                                    </a>

                                    <div class="content">
                                        <p>Yes, the leads are tailored for your selected area and not shared with multiple agents.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item active">
                                    <a href="#" class="heading" >
                                        <div class="icon"><i class="fa-solid fa-chevron-right"></i></div>
                                        <div class="title">How soon will I receive the leads?</div>
                                    </a>

                                    <div class="content" style={{ display: "block" }}>
                                        <p>Typically within 48 hours after our confirmation call.

                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"><i class="fa-solid fa-chevron-right"></i></div>
                                        <div class="title">What information do the leads include?</div>
                                    </a>

                                    <div class="content">
                                        <p>Name, contact information, property details, and insights based on the lead type.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"><i class="fa-solid fa-chevron-right"></i></div>
                                        <div class="title">Are these leads guaranteed to convert?</div>
                                    </a>

                                    <div class="content">
                                        <p>While we provide high-intent leads, success depends on your follow-up strategy.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"><i class="fa-solid fa-chevron-right"></i></div>
                                        <div class="title">Can I choose more than one lead type?</div>
                                    </a>

                                    <div class="content">
                                        <p>Yes, you can select multiple lead types based on your business goals.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="questions-img">
                            <img src="https://placehold.co/746x684" alt="questions" />
                        </div>
                    </div>
                </div>
            </div>
            <img alt="img" class="shaps-dots" src="/greenTheme/assets/img/shaps-dots.png" />
        </section>
        <footer class="footer" style={{ backgroundImage: "url(https://placehold.co/1920x952)" }}>
            <div class="container">
                <div class="heading">
                    <h2>Start Your Business and <span>Achieve Goals</span></h2>
                    <p>Great and strong landing page made to enhance the marketer's workflow.</p>
                    <a href="#" class="btn">Get a Quote</a>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="address">
                            <i>
                                <svg width="47" height="50" viewBox="0 0 47 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_17_753" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-4" y="0" width="51" height="50">
                                        <path d="M-3.3158 0.10495H46.4211V49.8418H-3.3158V0.10495Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_17_753)">
                                        <path d="M36.5038 43.0766C36.5038 46.2763 33.9101 48.8701 30.7102 48.8701H24.4349C22.2404 48.8701 20.2344 47.6306 19.2533 45.6673L17.9574 43.0766L15.0606 48.8701L12.1637 43.0766L9.26706 48.8701L6.37028 43.0766L3.4734 48.8701L0.576715 43.0766L-2.32007 48.8701" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M22.5484 25.9446C22.5484 26.481 22.1134 26.916 21.577 26.916C21.0405 26.916 20.6055 26.481 20.6055 25.9446C20.6055 25.4082 21.0405 24.9732 21.577 24.9732C22.1134 24.9732 22.5484 25.4082 22.5484 25.9446Z" fill="black" />
                                        <path d="M18.5355 22.8072C17.3528 21.4293 16.2909 20.0241 15.4209 18.6625C14.7041 17.5415 14.9208 16.0562 15.8621 15.1147L19.1319 11.845C19.8352 11.1417 19.8352 10.0013 19.1319 9.29796L11.4372 1.60332C10.7338 0.900013 9.59338 0.900013 8.8891 1.60332L6.94236 3.55103C3.78242 6.71107 2.92747 11.5322 4.91122 15.5374C8.44137 22.6628 16.8694 34.7502 30.8627 41.6114C34.8785 43.5804 39.792 42.745 42.955 39.583L44.9465 37.5916C45.6497 36.8873 45.6497 35.7469 44.9465 35.0436L37.1838 27.3499C36.4795 26.6456 35.3391 26.6456 34.6357 27.3499L31.3659 30.6188C30.4255 31.5601 28.9402 31.7767 27.8183 31.0598C26.8289 30.4278 25.8162 29.6942 24.8075 28.8862" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                </svg>
                            </i>
                            <div>
                                <h3>phone:</h3>
                                <p>Home:<a href="callto:+1238717662">+1 23871 7662</a></p>
                                <p>Office:<a href="callto:+1458794512">+1 458794512</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="address location">
                            <div><i>
                                <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_17_805" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="55" height="55">
                                        <path d="M0 -7.62939e-06H55V55H0V-7.62939e-06Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_17_805)">
                                        <path d="M11.3867 53.9258H43.6133" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M27.5 29.0039C22.7616 29.0039 18.9062 25.1485 18.9062 20.4102C18.9062 15.6718 22.7616 11.8164 27.5 11.8164C32.2384 11.8164 36.0938 15.6718 36.0938 20.4102C36.0938 25.1485 32.2384 29.0039 27.5 29.0039Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M38.2422 40.3906C38.2422 40.9839 37.7612 41.4648 37.168 41.4648C36.5748 41.4648 36.0938 40.9839 36.0938 40.3906C36.0938 39.7973 36.5748 39.3164 37.168 39.3164C37.7612 39.3164 38.2422 39.7973 38.2422 40.3906Z" fill="black" />
                                        <path d="M34.3065 44.2846L27.5001 53.9258L12.0216 32.001C9.59928 28.7709 8.16412 24.7586 8.16412 20.4101C8.16412 9.73134 16.8213 1.07421 27.5001 1.07421C38.1789 1.07421 46.836 9.73134 46.836 20.4101C46.836 24.7586 45.4007 28.7709 42.9785 32.001L39.8795 36.3907" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                </svg>
                            </i></div>
                            <div>
                                <h3>Address</h3>
                                <p>New town 245 Go Up Street Ltd<br /> 71 United State</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="address email">
                            <div><i>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_17_765)">
                                        <path d="M45.5384 8H2.46159C1.10766 8 0 9.10766 0 10.4615V37.5385C0 38.8922 1.10766 40.0001 2.46159 40.0001H45.5385C46.8923 40.0001 48.0001 38.8924 48.0001 37.5385V10.4615C48 9.10766 46.8923 8 45.5384 8ZM44.6147 9.84603L25.416 24.2462C25.0708 24.5089 24.5409 24.6732 23.9999 24.6708C23.459 24.6732 22.9292 24.5089 22.5839 24.2462L3.38522 9.84603H44.6147ZM34.3594 25.1964L44.8209 38.1195C44.8314 38.1325 44.8443 38.1423 44.8554 38.154H3.14456C3.15562 38.1418 3.16856 38.1325 3.17906 38.1195L13.6406 25.1964C13.9612 24.8001 13.9004 24.2191 13.5034 23.8978C13.1071 23.5773 12.5261 23.6381 12.2055 24.0344L1.84612 36.8314V10.9999L21.4768 25.723C22.2147 26.2725 23.1125 26.5144 23.9998 26.5168C24.8859 26.515 25.7844 26.2731 26.5228 25.723L46.1535 10.9999V36.8312L35.7944 24.0344C35.4738 23.6382 34.8923 23.5772 34.4965 23.8978C34.0996 24.2184 34.0387 24.8001 34.3594 25.1964Z" fill="black" />
                                    </g>
                                </svg>
                            </i></div>
                            <div>
                                <h3>Email:</h3>
                                <a href="mailto:info@domainname.com">+info@domainname.com</a>
                                <a href="mailto:business@domain.com">business@domain.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <a href="#"><img src="/greenTheme/assets/img/logo-w.png" alt="logo" /></a>
                    <p>© 2023 Anex by Winsfolio. All Rights Reserved.</p>
                    <ul class="social-icon">
                        <li><a href="#"><i class="fa-brands fa-facebook"></i></a></li>
                        <li><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </footer>

        {/* below this alla re the static fiedl

        <section id="about" class="unlimited-design no-bottom gap bg-light" style={{ height: "initial", backgroundImage: "url(/greenTheme/assets/img/background_1.png)" }}>
            <div class="container">
                <div class="heading">
                    <h2>Unlimited design at the <span>next level</span></h2>
                    <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p>
                </div>
                <div class="row background-color">
                    <div class="offset-lg-1 col-lg-10">
                        <img class="position-relative" src="https://placehold.co/1076x445" alt="img" />
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="count-style">
                                    <div class="count-text">
                                        <h3 data-max="40"><sub>+</sub></h3>
                                        <span>Professional Team Members</span>
                                    </div>
                                    <img src="/greenTheme/assets/img/certification-1.png" alt="certification-1" />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="count-style">
                                    <div class="count-text">
                                        <h3 data-max="14"><sub>k</sub></h3>
                                        <span>Completed Projects</span>
                                    </div>
                                    <img src="/greenTheme/assets/img/project-management-1.png" alt="certification-1" />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="count-style">
                                    <div class="count-text">
                                        <h3 data-max="10"><sub>k</sub></h3>
                                        <span>Happy Satisfied Customers</span>
                                    </div>
                                    <img src="/greenTheme/assets/img/bad-review-1.png" alt="certification-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img alt="img" class="shaps-line" src="/greenTheme/assets/img/shaps-dots.png" />
            <img alt="img" class="shaps-dots" src="/greenTheme/assets/img/shaps-dots.png" />
        </section >


        <section style={{ height: "initial" }} id="team" class="meet-our gap bg-light">
            <div class="container">
                <div class="heading two">
                    <h2>Meet our <span>awesome</span> people</h2>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="clients-data">
                            <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p>
                            <div class="clients-rate" style={{ backgroundImage: "url(/greenTheme/assets/img/background-line.png)" }}>
                                <img src="/greenTheme/assets/img/logo-anex-2.png" alt="img" />
                                <h3>4.9<span>/5</span></h3>
                                <p>Clients rate professionals on Google.</p>
                                <ul class="stars">
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                    <li><i class="fa-solid fa-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-style">
                            <img src="https://placehold.co/306x279" alt="img" />
                            <div class="team-text">
                                <h3>Oliva Rahina</h3>
                                <h4>Web Developer</h4>
                                <h6>ph.<a href="callto:+012342543378">+01 2342543378</a></h6>
                                <a href="#" class="brand"><i class="fa-brands fa-facebook"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="team-style two">
                            <img src="https://placehold.co/208x254" alt="img" />
                            <div class="team-text">
                                <h3>Romina Ron</h3>
                                <h4>Expert Consultant</h4>
                                <h6>ph.<a href="callto:+012342543378">+01 2342543378</a></h6>
                                <a href="#" class="brand"><i class="fa-brands fa-instagram"></i></a>
                            </div>
                        </div>
                        <div class="team-style two mt-4">
                            <img src="https://placehold.co/208x254" alt="img" />
                            <div class="team-text">
                                <h3>Petar Poline</h3>
                                <h4>Graphic Designer</h4>
                                <h6>ph.<a href="callto:+012342543378">+01 2342543378</a></h6>
                                <a href="#" class="brand"><i class="fa-brands fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style={{ height: "initial" }} class="gap client-reviews bg-light">
            <div class="container">
                <div class="quotation owl-carousel">
                    <div class="quotation-mark item">
                        <figure><img alt="quotation" src="https://placehold.co/177x177" /></figure>
                        <h6>Martha Smith <span>at Google</span></h6>
                        <ul class="stars">
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                        </ul>
                        <p>“This is one the most transparent referral programs I’ve ever used before. Besides, it’s double-sided and it lets me not only share a great service with my friends, but to earn and make them earn as well. I earned money by sharing referral link on Facebook.”</p>
                    </div>
                    <div class="quotation-mark item">
                        <figure><img alt="quotation" src="https://placehold.co/177x177" /></figure>
                        <h6>Martha Smith <span>at Google</span></h6>
                        <ul class="stars">
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                        </ul>
                        <p>“This is one the most transparent referral programs I’ve ever used before.but to earn and make them earn as well. I earned money by sharing referral link on Facebook. Besides, it’s double-sided and it lets me not only share a great service with my friends”</p>
                    </div>
                </div>
            </div>
            <div class="quotation-mark-img">
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
                <img src="https://placehold.co/85x85" alt="img" />
            </div>
        </section>

        <section id="pricing" class="pricing-plan-section gap bg-light" style={{ height: "initial", backgroundImage: "url(/greenTheme/assets/img/background_1.png)" }}>
            <div class="container">
                <div class="heading">
                    <h2>Choose a plan that <span>works</span> for you</h2>
                    <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-plan-style">
                            <h4>Individuals</h4>
                            <p>ecommerce solution and a marketing hub, all in one.</p>
                            <ul>
                                <li>24x7 Premium support</li>
                                <li>Site Optimisation</li>
                                <li>Monthly Report</li>
                                <li>No Minimum Term</li>
                            </ul>
                            <h5>$90<span>/ month</span></h5>
                            <a href="#" class="btn">Subscribe Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-plan-style">
                            <h4>Professionals</h4>
                            <p>ecommerce solution and a marketing hub, all in one.</p>
                            <ul>
                                <li>24x7 Premium support</li>
                                <li>Site Optimisation</li>
                                <li>Monthly Report</li>
                                <li>No Minimum Term</li>
                            </ul>
                            <h5>$160<span>/ month</span></h5>
                            <a href="#" class="btn">Subscribe Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="pricing-plan-style mb-0">
                            <h4>Business</h4>
                            <p>ecommerce solution and a marketing hub, all in one.</p>
                            <ul>
                                <li>24x7 Premium support</li>
                                <li>Site Optimisation</li>
                                <li>Monthly Report</li>
                                <li>No Minimum Term</li>
                            </ul>
                            <h5>$290<span>/ yearly</span></h5>
                            <a href="#" class="btn">Subscribe Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <img alt="img" class="shaps-dots" src="/greenTheme/assets/img/shaps-dots.png" />
        </section>



        <section style={{ height: "initial" }} class="gap no-top bg-light">
            <div class="container">
                <div class="heading">
                    <h2>Recent <span>News</span></h2>
                    <p>component library to build your own components and pieces of UI. Reuse existing Hero, sections</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="blog-style">
                            <div class="date">
                                <a href="#"><i class="fa-regular fa-calendar"></i>Dec 14, 2024</a>
                            </div>
                            <img src="https://placehold.co/400x280" alt="blog" />
                            <h4>Develoment</h4>
                            <a href="#">Hiring Your Startup’s First Customer Success Lead</a>
                            <ul class="list-unstyled blog-card__meta">
                                <li>
                                    <a href="#">
                                        <i>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_17_666)">
                                                    <path d="M17.0711 12.9289C15.9819 11.8398 14.6855 11.0335 13.2711 10.5454C14.786 9.50199 15.7812 7.75578 15.7812 5.78125C15.7812 2.59348 13.1878 0 10 0C6.81223 0 4.21875 2.59348 4.21875 5.78125C4.21875 7.75578 5.21402 9.50199 6.72898 10.5454C5.31453 11.0335 4.01813 11.8398 2.92895 12.9289C1.0402 14.8177 0 17.3289 0 20H1.5625C1.5625 15.3475 5.34754 11.5625 10 11.5625C14.6525 11.5625 18.4375 15.3475 18.4375 20H20C20 17.3289 18.9598 14.8177 17.0711 12.9289ZM10 10C7.67379 10 5.78125 8.1075 5.78125 5.78125C5.78125 3.455 7.67379 1.5625 10 1.5625C12.3262 1.5625 14.2188 3.455 14.2188 5.78125C14.2188 8.1075 12.3262 10 10 10Z" fill="black" />
                                                </g>
                                            </svg>
                                        </i> by Admin</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.625 2.0625H1.375C0.9955 2.0625 0.6875 2.3705 0.6875 2.75V19.5938C0.6875 19.877 0.861437 20.131 1.12509 20.2342C1.20656 20.2658 1.29112 20.2812 1.375 20.2812C1.56372 20.2812 1.74831 20.2036 1.881 20.0592L5.47181 16.1562H20.625C21.0045 16.1562 21.3125 15.8483 21.3125 15.4688V2.75C21.3125 2.3705 21.0045 2.0625 20.625 2.0625ZM19.9375 14.7812H5.17C4.9775 14.7812 4.79428 14.8617 4.664 15.0033L2.0625 17.831V3.4375H19.9375V14.7812Z" fill="black" />
                                                <path d="M8.42188 7.90625H13.5781C13.9576 7.90625 14.2656 7.59825 14.2656 7.21875C14.2656 6.83925 13.9576 6.53125 13.5781 6.53125H8.42188C8.04237 6.53125 7.73438 6.83925 7.73438 7.21875C7.73438 7.59825 8.04237 7.90625 8.42188 7.90625Z" fill="black" />
                                                <path d="M8.42188 12.0312H13.5781C13.9576 12.0312 14.2656 11.7233 14.2656 11.3438C14.2656 10.9642 13.9576 10.6562 13.5781 10.6562H8.42188C8.04237 10.6562 7.73438 10.9642 7.73438 11.3438C7.73438 11.7233 8.04237 12.0312 8.42188 12.0312Z" fill="black" />
                                            </svg>
                                        </i> 2 Comments</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="blog-style">
                            <div class="date">
                                <a href="#"><i class="fa-regular fa-calendar"></i>Dec 14, 2024</a>
                            </div>
                            <img src="https://placehold.co/400x280" alt="blog" />
                            <h4>Develoment</h4>
                            <a href="#">Hiring Your Startup’s First Customer Success Lead</a>
                            <ul class="list-unstyled blog-card__meta">
                                <li>
                                    <a href="#">
                                        <i>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_17_666)">
                                                    <path d="M17.0711 12.9289C15.9819 11.8398 14.6855 11.0335 13.2711 10.5454C14.786 9.50199 15.7812 7.75578 15.7812 5.78125C15.7812 2.59348 13.1878 0 10 0C6.81223 0 4.21875 2.59348 4.21875 5.78125C4.21875 7.75578 5.21402 9.50199 6.72898 10.5454C5.31453 11.0335 4.01813 11.8398 2.92895 12.9289C1.0402 14.8177 0 17.3289 0 20H1.5625C1.5625 15.3475 5.34754 11.5625 10 11.5625C14.6525 11.5625 18.4375 15.3475 18.4375 20H20C20 17.3289 18.9598 14.8177 17.0711 12.9289ZM10 10C7.67379 10 5.78125 8.1075 5.78125 5.78125C5.78125 3.455 7.67379 1.5625 10 1.5625C12.3262 1.5625 14.2188 3.455 14.2188 5.78125C14.2188 8.1075 12.3262 10 10 10Z" fill="black" />
                                                </g>
                                            </svg>
                                        </i> by Admin</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.625 2.0625H1.375C0.9955 2.0625 0.6875 2.3705 0.6875 2.75V19.5938C0.6875 19.877 0.861437 20.131 1.12509 20.2342C1.20656 20.2658 1.29112 20.2812 1.375 20.2812C1.56372 20.2812 1.74831 20.2036 1.881 20.0592L5.47181 16.1562H20.625C21.0045 16.1562 21.3125 15.8483 21.3125 15.4688V2.75C21.3125 2.3705 21.0045 2.0625 20.625 2.0625ZM19.9375 14.7812H5.17C4.9775 14.7812 4.79428 14.8617 4.664 15.0033L2.0625 17.831V3.4375H19.9375V14.7812Z" fill="black" />
                                                <path d="M8.42188 7.90625H13.5781C13.9576 7.90625 14.2656 7.59825 14.2656 7.21875C14.2656 6.83925 13.9576 6.53125 13.5781 6.53125H8.42188C8.04237 6.53125 7.73438 6.83925 7.73438 7.21875C7.73438 7.59825 8.04237 7.90625 8.42188 7.90625Z" fill="black" />
                                                <path d="M8.42188 12.0312H13.5781C13.9576 12.0312 14.2656 11.7233 14.2656 11.3438C14.2656 10.9642 13.9576 10.6562 13.5781 10.6562H8.42188C8.04237 10.6562 7.73438 10.9642 7.73438 11.3438C7.73438 11.7233 8.04237 12.0312 8.42188 12.0312Z" fill="black" />
                                            </svg>
                                        </i> 2 Comments</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="blog-style mb-0">
                            <div class="date">
                                <a href="#"><i class="fa-regular fa-calendar"></i>Dec 14, 2024</a>
                            </div>
                            <img src="https://placehold.co/400x280" alt="blog" />
                            <h4>Develoment</h4>
                            <a href="#">Hiring Your Startup’s First Customer Success Lead</a>
                            <ul class="list-unstyled blog-card__meta">
                                <li>
                                    <a href="#">
                                        <i>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_17_666)">
                                                    <path d="M17.0711 12.9289C15.9819 11.8398 14.6855 11.0335 13.2711 10.5454C14.786 9.50199 15.7812 7.75578 15.7812 5.78125C15.7812 2.59348 13.1878 0 10 0C6.81223 0 4.21875 2.59348 4.21875 5.78125C4.21875 7.75578 5.21402 9.50199 6.72898 10.5454C5.31453 11.0335 4.01813 11.8398 2.92895 12.9289C1.0402 14.8177 0 17.3289 0 20H1.5625C1.5625 15.3475 5.34754 11.5625 10 11.5625C14.6525 11.5625 18.4375 15.3475 18.4375 20H20C20 17.3289 18.9598 14.8177 17.0711 12.9289ZM10 10C7.67379 10 5.78125 8.1075 5.78125 5.78125C5.78125 3.455 7.67379 1.5625 10 1.5625C12.3262 1.5625 14.2188 3.455 14.2188 5.78125C14.2188 8.1075 12.3262 10 10 10Z" fill="black" />
                                                </g>
                                            </svg>
                                        </i> by Admin</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.625 2.0625H1.375C0.9955 2.0625 0.6875 2.3705 0.6875 2.75V19.5938C0.6875 19.877 0.861437 20.131 1.12509 20.2342C1.20656 20.2658 1.29112 20.2812 1.375 20.2812C1.56372 20.2812 1.74831 20.2036 1.881 20.0592L5.47181 16.1562H20.625C21.0045 16.1562 21.3125 15.8483 21.3125 15.4688V2.75C21.3125 2.3705 21.0045 2.0625 20.625 2.0625ZM19.9375 14.7812H5.17C4.9775 14.7812 4.79428 14.8617 4.664 15.0033L2.0625 17.831V3.4375H19.9375V14.7812Z" fill="black" />
                                                <path d="M8.42188 7.90625H13.5781C13.9576 7.90625 14.2656 7.59825 14.2656 7.21875C14.2656 6.83925 13.9576 6.53125 13.5781 6.53125H8.42188C8.04237 6.53125 7.73438 6.83925 7.73438 7.21875C7.73438 7.59825 8.04237 7.90625 8.42188 7.90625Z" fill="black" />
                                                <path d="M8.42188 12.0312H13.5781C13.9576 12.0312 14.2656 11.7233 14.2656 11.3438C14.2656 10.9642 13.9576 10.6562 13.5781 10.6562H8.42188C8.04237 10.6562 7.73438 10.9642 7.73438 11.3438C7.73438 11.7233 8.04237 12.0312 8.42188 12.0312Z" fill="black" />
                                            </svg>
                                        </i> 2 Comments</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}



        <div id="progress">
            <span id="progress-value"><i class="fa-solid fa-arrow-up"></i></span>
        </div>

    </>

}