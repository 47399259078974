import React, { useEffect, useState } from "react";
import { CreateLeadWithoutTokenService, getAllSourceForLead, getfunnelForUser, getUserIdFromPostcardURL } from "../../Redux/Services/service";
import { useParams } from "react-router-dom";
import { GetCardByIdService, GetCardByNameService, getSourceNameformFunnelAPI, sendNotificationForPostcardAPI } from "../../Redux/Services/MarketingServices";
import { useTheme } from "@emotion/react";
import { getProfileById } from "../../Redux/Services/listingService";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { QrCode } from "@mui/icons-material";
import QRCode from "qrcode.react";


const ParamsPostCard = "Postcard";


function CustomFunnel() {
  const { user_id, link } = useParams();

  const [inputData, setInputData] = useState({});
  const [inputError, setInputError] = useState();
  const [kastDetail, setKastDetails] = React.useState({});
  const [funnelDetails, setFunnelDetails] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [sourceId, setSourceId] = React.useState(0)
  const [userId, setUserId] = React.useState(0)



  const getLink = async () => {
    const userResponse = await getUserIdFromPostcardURL(window.location.href.split("/")[window.location.href.split("/").length - 1]);
    console.log({ userResponse })
    setUserId(userResponse.data.data[0]?.user_id)
  }
  React.useEffect(() => {
    getLink()
  }, [])


  const getUserDetails = async () => {
    if (user_id == ParamsPostCard) {
      getFunnelDetail(userId);
      // const card = await GetCardByNameService(user_id);
      const card = await getProfileById(userId)
      console.log("profile", card.data.profile)
      await sendNotificationForPostcardAPI(card.data.profile[0].created_by, "goldencity")
      setKastDetails(card.data.profile.map((i) => ({ first_name: i.full_name.split(" ")[0], last_name: i.full_name.split(" ")[1], tagline: i.motto_mission_tagline, job_title: i.job_title }))[0]);
      setLoading(false);
    } else {
      const card = await GetCardByNameService(user_id);
      console.log("this is the kast details==>", card.data.data[0].id)
      const allKastDetails = await GetCardByIdService(card.data.data[0].id)
      console.log("all kast details", allKastDetails.data.data);
      setKastDetails(allKastDetails.data.data);
      getFunnelDetail(card.data.data[0].created_by);
    }
  };

  const getFunnelDetail = (id) => {
    getfunnelForUser(id, "goldencity").then(val => {
      console.log(val);
      const funnelDetails = val.data.funnel.filter(_i => _i.funnel_link == "goldencity");
      console.log("this is funnel ddetails ==> ", funnelDetails)

      setFunnelDetails(funnelDetails[0])
      setLoading(false);
    })
  }


  const getFunnelSourceId = async () => {
    console.log("funnel ID", funnelDetails.funnel_id)
    if (funnelDetails?.id) {
      const respo = await getSourceNameformFunnelAPI(funnelDetails.funnel_id)
      console.log("funnel id", respo.data.data.id)
      setSourceId(respo.data.data.id)
    }
  }

  React.useEffect(() => {
    getFunnelSourceId()
  }, [funnelDetails])



  React.useEffect(() => {
    getUserDetails();
  }, [user_id, userId]);

  const [open, setOpen] = useState(false);

  const handleChangeInputValue = (e) => {
    setInputData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // validation
  const handleFunnelFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!inputData?.email) {
      formIsValid = false;
      formErrors["email_error"] = "Please provide your email.";
    }

    setInputError(formErrors);
    return formIsValid;
  };


  const funnelDetailSubmit = async (e) => {

    console.log(sourceId)
    e.preventDefault();
    if (handleFunnelFormValidation()) {

      let sourcePostcard = 0
      if (user_id == ParamsPostCard) {
        const sourceResp = await getAllSourceForLead({ name: "Postcard/" + link });
        console.log({ sourceResp })
        sourcePostcard = sourceResp.data.data[0].id;
      }
      const datas = {
        type: funnelDetails.funnel_type == "Buyer" ? "B" : funnelDetails.funnel_type == "Seller" ? "S" : "O",
        lead_type: user_id == ParamsPostCard ? "W" : "C",
        first_name: inputData.fullName ?? "-",
        last_name: "-",
        mobile_1: inputData.phone ?? "-",
        mobile_2: "",
        mobile_3: "",
        email: inputData.email,
        email_2: "",
        address_1: "",
        address_2: "",
        full_address: "",
        city: "",
        state: "",
        zip_code: "",
        source_id: sourceId,
        agent_id: 1,
        listing_agent: 1,
        company_attorney: 1,
        loan_officer: 1,
        insurance_agent: 1,
        label: "",
        notes: inputData.type,
        price_range: 1,
        target_city: 1,
        closing_date: "2023-03-04 19:55:02",
        bedroom: "",
        bathroom: "",
        garage: "",
        custom_tag: "",
        nurture: "",
        tag: "",
        summary: "",
        openfield1: "open",
        openfield2: "open",
        openfield3: "open",
        openfield4: "open",
        openfield5: "open",
        openfield6: "open",
        openfield7: "open",
        openfield8: "open",
        openfield9: "open",
        openfield10: "open",
        is_active: "Y",
        is_arch: "N",
        is_verified: "N",
        created_by: user_id == ParamsPostCard ? userId : parseInt(kastDetail?.created_by),
      };
      CreateLeadWithoutTokenService(datas)
        .then((res) => {
          setOpen(true);
          if (res.status == 200) {
            setInputData({});
            window.location.href = funnelDetails.pdf_link
            return;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };


  useEffect(() => {
    const odometers = document.querySelectorAll(".odometer");

    const handleScroll = () => {
      odometers.forEach((odometer) => {
        const rect = odometer.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          const countNumber = odometer.getAttribute("data-count");
          odometer.innerHTML = countNumber ?? "0";
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount in case it's already in view

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>


      <section style={{ height: "auto" }} id="hero" className="hero hero__padding overflow-hidden position-relative bg-one">
        <div className="circle x1"></div>
        <div className="circle x2"></div>
        <div className="circle x3"></div>
        <div className="circle x4"></div>
        <div className="circle x5"></div>
        <div className="container">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="hero__content position-relative">
                <div className="badge-text mb-2 fs-3 fw-bold" data-aos="fade-up" data-aos-duration="1000"
                  data-aos-delay="600">
                  Your Gateway to Smart Real Estate Investments – Powered by TrueVisory!
                </div>

                <p className="text-muted mb-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="700">
                  Exclusive access to premium real estate projects. Discover your next big investment today!
                </p>


                <div id="subscribe" className="col-12" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">

                  {/* Name Field */}
                  <div className="input-group mt-3">
                    <div className="input-size p-0">
                      <input type="text" onChange={handleChangeInputValue} name="fullName" className="form-control p-2" style={{ borderRadius: 10, border: "none" }} placeholder="Name" />
                    </div>
                  </div>

                  {/* Email Field */}
                  <div className="input-group mt-3">
                    <div className="input-size p-0">
                      <input type="email" onChange={handleChangeInputValue} name="email" className="form-control p-2" style={{ borderRadius: 10, border: "none" }} placeholder="Email" id="subscriber-email" />
                    </div>
                  </div>

                  {/* Phone Field */}
                  <div className="input-group mt-3">
                    <div className="input-size p-0">
                      <input type="text" onChange={handleChangeInputValue} name="phone" className="form-control p-2" style={{ borderRadius: 10, border: "none" }} placeholder="Phone" />
                    </div>
                  </div>

                  {/* Select Dropdown */}
                  <div className="input-group mt-3">
                    <div className="input-size p-0">
                      <select className="form-control p-2" name="type" onChange={handleChangeInputValue} style={{ border: "none", backgroundColor: "#fff" }}>
                        <option>Villa</option>
                        <option>Apartment</option>
                        <option>Plot</option>
                      </select>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="input-group mt-3 w-100">
                    <div className="input-size p-0">
                      <button onClick={funnelDetailSubmit} className="button button__primary w-100 p-2" style={{ borderRadius: 10, border: "none" }} id="subscribe-button">
                        <span>Get More Details</span>
                      </button>
                    </div>
                  </div>

                </div>


              </div>
            </div>
            <div className="col-lg-6 d-flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="700">
              <div className="hero__book">
                <div className="hero__book--wrapper">
                  <img className="img-fluid" src="/new_client_images/hero1.jpg" alt="" />
                </div>
              </div>
              <div className="hero__author text-center">
                <div className="hero__author--inner hero__author--inner2">
                  <div
                    className="hero__author--inner--pic hero__author--inner2--pic d-flex align-items-end justify-content-center"
                    style={{ backgroundImage: "url(/new_client_images/hero2.jpg)" }}></div>
                  <div className="frame frame-1"></div>
                  <div className="frame frame-2"></div>
                  <div className="frame frame-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <section style={{ height: "auto" }} id="about" className="section-padding authorv2 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <span data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">About me</span>
                <h2 className="display-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                  About Us

                </h2>
                <div className="section-divider divider-traingle" data-aos="fade-up" data-aos-duration="1000"
                  data-aos-delay="250"></div>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-lg-6 mb-4 mb-lg-0" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
              <div className="authorv2__image" style={{ aspectRatio: 1 }}>
                <img className="img-fluid" src={kastDetail.profile_photo} alt="Author Image" style={{ width: "100%", height: "100%" }} />
                {/* <a href="https://youtu.be/qg0_FinB6EE" className="glightbox3 video-btn">
                  <i className="icofont-play"></i></a> */}

              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
              <p>
                {kastDetail.tagline}
              </p>
              <div className="authorv2__content">
                <div className="authorv2__list">
                  <div className="row">
                    <div className="col-6 mb-3">
                      <h4>Name:</h4>
                      <p className="mb-0">{kastDetail.first_name}</p>
                    </div>
                    <div className="col-6 mb-3">
                      <h4>Mobile:</h4>
                      <p className="mb-0">{kastDetail.mobile}</p>
                    </div>
                    <div className="col-6 mb-3">
                      <h4>Company:</h4>
                      <p className="mb-0">{kastDetail.company_name}</p>
                    </div>
                    <div className="col-6 mb-3" >
                      <h4>Email ID:</h4>
                      <p className="mb-0" style={{ wordWrap: "break-word" }}>{kastDetail.email}</p>
                    </div>
                    <div className="col-6 mb-3">
                      <h4>Office Phone:</h4>
                      <p className="mb-0">{kastDetail.officePhone}</p>
                    </div>
                    <div className="col-6">
                      <h4>Job title:</h4>
                      <p className="mb-0">{kastDetail.job_title}</p>
                    </div>
                  </div>
                </div>
                <ul className="social-icon mt-3">
                  {
                    kastDetail?.social_details?.filter(_i => _i.type == 'F').length ?
                      <li>
                        <a href={kastDetail?.social_details?.filter(_i => _i.type == 'F')[0].url}><i className="icofont-facebook"></i></a>
                      </li> : null
                  }
                  {
                    kastDetail?.social_details?.filter(_i => _i.type == 'T').length ?
                      <li>
                        <a href={kastDetail?.social_details?.filter(_i => _i.type == 'T')[0].url}><i className="icofont-twitter"></i></a>
                      </li> : null
                  }
                  {
                    kastDetail?.social_details?.filter(_i => _i.type == 'L').length ?
                      <li>
                        <a href={kastDetail?.social_details?.filter(_i => _i.type == 'L')[0].url}><i className="icofont-linkedin"></i></a>
                      </li> : null
                  }
                  {
                    kastDetail?.social_details?.filter(_i => _i.type == 'I').length ?
                      <li>
                        <a href={kastDetail?.social_details?.filter(_i => _i.type == 'L')[0].url}><i className="icofont-instagram"></i></a>
                      </li> : null
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "auto" }} id="books" className="booksv1 section-padding bg-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <span data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">Why Invest in Golden City?
                </span>
                <h2 className="display-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">

                </h2>
                <div className="section-divider divider-traingle" data-aos="fade-up" data-aos-duration="1000"
                  data-aos-delay="250"></div>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-lg-6 mb-4 mb-lg-0" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
              <div className="row">
                <div className="col-6">
                  <div className="booksv1__img--wrap">
                    <div className="booksv1__img">
                      <img className="img-fluid" src="/new_client_images/hero3.png" alt="Book name" />
                    </div>
                    <div className="booksv1__img">
                      <img className="img-fluid" src="/new_client_images/2.jpg" alt="Book name" />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="booksv1__img--wrap m-50px-t md-m-0px-t">
                    <div className="booksv1__img">
                      <img className="img-fluid" src="/new_client_images/4.jpg" alt="Book name" />
                    </div>
                    <div className="booksv1__img">
                      <img className="img-fluid" src="/new_client_images/hero4.png" alt="Book name" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
              <div className="booksv1__contnet">
                {/* <div className="booksv1__contnet--badge mb-0">
                  Best selling books
                </div> */}
                {/* <h3 className="display-6 m-30px-b">Books released in 2022</h3> */}
                <div className="booksv1__item--wrap">
                  <div className="booksv1__item">
                    <div className="booksv1__item--icon">
                      <i className="icofont-touch"></i>
                    </div>
                    <div className="booksv1__item--text">
                      <h3>Strategic Location</h3>
                      <p>
                        Close to major roads, business hubs, and upcoming infrastructure projects
                      </p>
                    </div>
                  </div>
                  <div className="booksv1__item">
                    <div className="booksv1__item--icon">
                      <i className="icofont-touch"></i>
                    </div>
                    <div className="booksv1__item--text">
                      <h3>Premium Development</h3>
                      <p>
                        Modern amenities, smart infrastructure, and eco-friendly designs
                      </p>
                    </div>
                  </div>
                  <div className="booksv1__item">
                    <div className="booksv1__item--icon">
                      <i className="icofont-touch"></i>
                    </div>
                    <div className="booksv1__item--text">
                      <h3>High Appreciation Potential</h3>
                      <p>

                        A lucrative investment with strong market demand

                      </p>
                    </div>
                  </div>
                  <div className="booksv1__item">
                    <div className="booksv1__item--icon">
                      <i className="icofont-touch"></i>
                    </div>
                    <div className="booksv1__item--text">
                      <h3>Turnkey Investment Support</h3>
                      <p>
                        End-to-end guidance from TrueVisory to maximize your returns
                      </p>
                    </div>
                  </div>

                  <div className="booksv1__item">
                    <div className="booksv1__item--icon">
                      <i className="icofont-touch"></i>
                    </div>
                    <div className="booksv1__item--text">
                      <h3>Secure & Gated Community</h3>
                      <p>
                        Ensuring privacy, security, and a high-quality lifestyle
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "auto" }} className="section-padding counters bg-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <span data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">AWESOME STATS</span>
                <h2 className="display-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                  All Milestones Achieved
                </h2>
                <div className="section-divider divider-traingle" data-aos="fade-up" data-aos-duration="1000"
                  data-aos-delay="250"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="counters__stats m-0 p-0 d-flex flex-wrap align-items-center justify-content-center">

                <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                  <div className="counters__stats-box h-100 translateEffect1">
                    <div className="counters__stats-icon">
                      <i className="icofont-read-book"></i>
                    </div>
                    <div className="counters__stats-box__number">
                      <span className="odometer" data-count="500"></span>+
                    </div>
                    <h5>Employees</h5>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                  <div className="counters__stats-box h-100 translateEffect1">
                    <div className="counters__stats-icon">
                      <i className="icofont-hour-glass"></i>
                    </div>
                    <div className="counters__stats-box__number">
                      <span className="odometer" data-count="35007"></span>+
                    </div>
                    <h5>Happy customer</h5>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                  <div className="counters__stats-box h-100 translateEffect1">
                    <div className="counters__stats-icon">
                      <i className="icofont-award"></i>
                    </div>
                    <div className="counters__stats-box__number">
                      <span className="odometer" data-count="35"></span>+
                    </div>
                    <h5>Developers Associated With Us</h5>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                  <div className="counters__stats-box h-100 translateEffect1">
                    <div className="counters__stats-icon">
                      <i className="icofont-award"></i>
                    </div>
                    <div className="counters__stats-box__number">
                      <span className="odometer" data-count="150+"></span>
                    </div>
                    <h5>Channel Partners</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "auto" }} className="books section-padding bg-one">
        <div className="container">
          <div className="row chapter-slider ">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <span data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150"></span>
                <h2 className="display-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                  What Our Clients Say About TrueVisory
                </h2>
                <div className="section-divider divider-traingle" data-aos="fade-up" data-aos-duration="1000"
                  data-aos-delay="250"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              // nav
              // navText={["<span class='nav-btn'>&#10094;</span>", "<span class='nav-btn'>&#10095;</span>"]}
              dots={true}
              responsive={{
                0: { items: 1 },
                600: { items: 2 },
                1000: { items: 4 },
              }}
            >


              <div className=" mb-4 mb-lg-0" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="books__book h-100 translateEffect1">
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a751a180c6edec28086e13_Loki%20Bright.png">
                    <img className="img-fluid" src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a751a180c6edec28086e13_Loki%20Bright.png" alt="Book 7" />
                  </a>
                  <p className="books__book__subtitle"> TrueVisory’s expert guidance made my real estate investment seamless and profitable. Highly recommended!</p>
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a751a180c6edec28086e13_Loki%20Bright.png">
                    <h3 className="books__book__title">Rohit S.</h3>
                  </a>

                  <div className="d-flex justify-content-between">
                    <span></span>
                    <ul>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className=" mb-4 mb-lg-0" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="books__book h-100 translateEffect1">
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a752b15d58d46d51503280_Olivia%20Rhye.png">
                    <img className="img-fluid" src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a752b15d58d46d51503280_Olivia%20Rhye.png" alt="Book 7" />
                  </a>
                  <p className="books__book__subtitle">The team at TrueVisory helped me find the best deal in Golden City. Their transparency and support were top-notch.</p>
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a752b15d58d46d51503280_Olivia%20Rhye.png">
                    <h3 className="books__book__title">Priya M.</h3>
                  </a>
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <ul>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className=" mb-4 mb-lg-0" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="books__book h-100 translateEffect1">
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://images.unsplash.com/photo-1633332755192-727a05c4013d?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXZhdGFyfGVufDB8fDB8fHww">
                    <img className="img-fluid" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXZhdGFyfGVufDB8fDB8fHww" alt="Book 7" />
                  </a>
                  <p className="books__book__subtitle">As a first-time investor, I was unsure, but TrueVisory's data-driven insights gave me the confidence to make the right decision!</p>
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://images.unsplash.com/photo-1633332755192-727a05c4013d?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXZhdGFyfGVufDB8fDB8fHww">
                    <h3 className="books__book__title">Vikram D.</h3>
                  </a>
                  {/* <div className="chapters__content glightbox-desc custom-desc2">
                    <div className="content__body">
                      <h3 className="mb-4 text-center">Rich dad, poor dad</h3>
                    </div>
                  </div> */}
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <ul>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className=" mb-4 mb-lg-0" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="books__book h-100 translateEffect1">
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a753049744df4a8765df41_Rene%20Wells.jpg">
                    <img className="img-fluid" src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a753049744df4a8765df41_Rene%20Wells.jpg" alt="Book 7" />
                  </a>
                  <p className="books__book__subtitle">TrueVisory goes beyond just selling properties. They truly care about your investment success.</p>
                  <a data-glightbox=" description: .custom-desc2; descPosition: right;" className="glightbox2"
                    href="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a753049744df4a8765df41_Rene%20Wells.jpg">
                    <h3 className="books__book__title">Ananya K.</h3>
                  </a>
                  {/* <div className="chapters__content glightbox-desc custom-desc2">
                    <div className="content__body">
                      <h3 className="mb-4 text-center">Rich dad, poor dad</h3>
                    </div>
                  </div> */}
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <ul>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                      <li><i className="icofont-star"></i></li>
                    </ul>
                  </div>
                </div>
              </div>



            </OwlCarousel>

          </div>
        </div>
      </section>
      <section style={{ height: "auto" }} id="achievement" className="section-padding achievement bg-white">
        <div className="container">
          <div className="row">


            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <span data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">Achievements</span>
                <h2 className="display-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                  Achievements of HavenHub Infra & TrueVisory

                </h2>
                <div className="section-divider divider-traingle" data-aos="fade-up" data-aos-duration="1000"
                  data-aos-delay="250"></div>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">

            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              // nav
              // navText={["<span class='nav-btn'>&#10094;</span>", "<span class='nav-btn'>&#10095;</span>"]}
              dots={true}
              autoPlay
              autoplayTimeout={1000}
              responsive={{
                0: { items: 1 },
                600: { items: 2 },
                1000: { items: 2 },
              }}
            >
              <div className="col m-30px-b" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                <div className="card h-100 translateEffect1">
                  <img src="/new_client_images/8.jpg" className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className="achievement__content">
                      <div className="achievement__content__icon">
                        <i className="icofont-award"></i>
                      </div>
                      <h3>HavenHub Infra</h3>
                      <p>
                        1. Successfully developed 10+ large-scale real estate projects<br />
                        2. Over 1000+ happy homeowners and investors<br />
                        3. Award-winning real estate developer recognized for sustainable and smart housing

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col m-30px-b" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                <div className="card h-100 translateEffect1">
                  <img src="/new_client_images/8.jpg" className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className="achievement__content">
                      <div className="achievement__content__icon">
                        <i className="icofont-award"></i>
                      </div>
                      <h3>HavenHub Infra</h3>
                      <p>
                        1. Successfully developed 10+ large-scale real estate projects<br />
                        2. Over 1000+ happy homeowners and investors<br />
                        3. Award-winning real estate developer recognized for sustainable and smart housing

                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </OwlCarousel>

          </div>
        </div>
      </section>

      <section style={{ height: "auto" }} id="chapters" class="section-padding bg-one">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div class="section-title-center text-center">
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="150"
                >Chapters</span
                >
                <h2
                  class="display-6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Frequently Asked Questions (FAQs)

                </h2>
                <div
                  class="section-divider divider-traingle"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="250"
                ></div>
              </div>
            </div>
          </div>
          <div class="row gx-5">

            <div
              class="col-lg-12"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div
                class="accordion accordion-flush faqs-accordion mt-4 mt-lg-0"
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      1. What is TrueVisory, and how does it help investors?

                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <p class="accordion-body text-secondary">
                      TrueVisory is a real estate investment advisory firm that simplifies property buying and selling. We provide data-driven insights, personalized recommendations, and end-to-end support to help clients make informed decisions.

                    </p>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      2. What makes the Golden City project a great investment?

                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <p class="accordion-body text-secondary">
                      Golden City is a premium residential and commercial development featuring top-tier infrastructure, high appreciation potential, and a prime location. It's backed by HavenHub Infra, known for delivering high-quality real estate projects.

                    </p>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      3. How does TrueVisory ensure a smooth buying experience?

                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <p class="accordion-body text-secondary">
                      We handle everything from property discovery, site visits, and legal verification to financing and final transactions. Our goal is to make the real estate investment journey hassle-free and transparent.

                    </p>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      4. What are the financing options available?

                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <p class="accordion-body text-secondary">
                      We partner with leading banks and financial institutions to offer home loans at competitive rates. Our team assists in securing financing based on your eligibility and investment goals.

                    </p>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      5. How do I get started with TrueVisory?

                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <p class="accordion-body text-secondary">
                      Simply fill out the form above, and our experts will get in touch with you for a free consultation. We’ll walk you through the available opportunities and help you make the best investment decision.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>





      <section style={{ height: "auto" }} id="contact" class="section-padding bg-white">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div class="section-title-center text-center">
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="150"
                >Contact</span
                >
                <h2
                  class="display-6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Write me anything
                </h2>
                <div
                  class="section-divider divider-traingle"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                ></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-7 col-md-6 col-sm-12 m-25px-b"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div class="contact-form-box">
                <form id="contact-form" method="post" action="php/sendmail.php">
                  <div class="message col">
                    <p class="email-loading alert alert-warning">
                      <img
                        src="assets/images/loading.gif"
                        alt=""
                      />&nbsp;&nbsp;&nbsp;Sending...
                    </p>
                    <p class="email-success alert alert-success">
                      <i class="icon icon-icon-check-alt2"></i> Your quote has
                      successfully been sent.
                    </p>
                    <p class="email-failed alert alert-danger">
                      <i class="icon icon-icon-close-alt2"></i> Something went
                      wrong!
                    </p>
                  </div>
                  <div class="mb13">
                    <input
                      name="name"
                      class="contact-name"
                      id="contact-name"
                      type="text"
                      placeholder="Your Name"
                      required=""
                    />
                  </div>
                  <div class="mb13">
                    <input
                      name="email"
                      class="contact-email"
                      id="contact-email"
                      type="email"
                      placeholder="Your Email"
                      required=""
                    />
                  </div>
                  <div class="mb13">
                    <input
                      name="subject"
                      class="contact-subject"
                      id="contact-subject"
                      type="text"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div class="mb30">
                    <textarea
                      name="message"
                      class="contact-message"
                      id="contact-message"
                      placeholder="Your Message"
                      required=""
                    ></textarea>
                  </div>
                  <button class="button button__primary align-items-center">
                    <span>Send Now</span>
                  </button>
                </form>
              </div>
            </div>
            <div
              class="col-lg-5 col-md-6 col-sm-12 m-25px-b"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div class="contact__address">
                <div id="map">
                  <iframe
                    src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  ></iframe>
                </div>
                <ul class="contactv4__content">
                  <li>
                    <i class="icofont-google-map"></i> 12 F/F Hargovind Enclave, Road Number 72, New Delhi, Shahdara, Delhi
                  </li>
                  <li>
                    <i class="icofont-email"></i>
                    <a href="mailto:support@truevisory.co.in">support@truevisory.co.in</a>
                  </li>
                  <li>
                    <i class="icofont-ui-call"></i>
                    <a href="tel:8008800977">+91-8008-8009-77</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "auto" }} id="ctav3" className="ctav3 bg-one p-80px-t">
        <div className="container bg-one">
          <div className="row">
            <div className="ctav3__content">
              <h2 className="m-25px-b" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">
                Do you have a question? <br />
                Feel free to contact me.
              </h2>
              <h2 className="display-4 m-25px-b" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                <a href="tel:610383766284">+91-8008-8009-77</a>
              </h2>
              <a href="#contact" className="smooth button button__primary" data-aos="fade-up" data-aos-duration="1000"
                data-aos-delay="250"><span>Send me a message</span></a>
              <div className="ctav3__content__icon">

                {/* <i className="icofont-read-book"></i> */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ justifyContent: "center", display: "flex", alignSelf: "center" }}>
          <QRCode value={window.location.href} size={100} bgColor="#FFFFFF" fgColor="#000000" />
        </div>
      </section>
    </>
  );
}


export default CustomFunnel;
