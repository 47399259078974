import { Grid } from "@mui/material";
import axios from "axios";
import React from "react"
const basicurl = " https://production-backend.kast.biz/";


export const ShowUserwithScript = () => {

    const [data, setData] = React.useState([]);


    React.useEffect(() => {
        getAllScript();
    }, [])

    const getAllScript = async () => {
        const res = await axios.get(basicurl + "api/get_all_script", {})
        console.log(res.data)
        setData(res.data)
    }


    return <>
        <Grid sx={{ p: 2, m: 5, background: "#fff" }}>
            <DataTable data={data} refresh={getAllScript} />
        </Grid>
    </>
}

const DataTable = ({ data, refresh }) => {

    const DeleteNow = async (id) => {
        await axios.delete(basicurl + "api/delete_script/" + id)
        refresh();
    }


    return (
        <div style={{ backgroundColor: "#fff", width: "100%" }}>
            <table border="1" style={{ textAlign: 'left', borderCollapse: 'collapse', padding: 20 }}>
                <thead>
                    <tr>
                        {/* Adjust the table headers according to your data */}
                        <th style={{ padding: 5 }}>Name</th>
                        <th style={{ padding: 5 }}>Email</th>
                        <th style={{ padding: 5 }}>Script</th>
                        <th style={{ padding: 5 }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 ? (
                        data.map((item, index) => (
                            <tr key={index}>
                                <td style={{ padding: 5 }}>{item.name}</td>
                                <td style={{ padding: 5 }}>{item.email}</td>
                                <td style={{ padding: 5 }}>{item.script}</td>
                                <td style={{ padding: 5 }} onClick={() => DeleteNow(item.id)}>{"Delete"}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" style={{ textAlign: 'center' }}>
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};