
import React from "react"
import { Alert, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import moment from "moment"
import { getProfileById } from "../../Redux/Services/listingService";
import { CreateLeadWithoutTokenService } from "../../Redux/Services/service";
import QRCode from "react-qr-code";
import { getSourceNameformListingAPI } from "../../Redux/Services/MarketingServices";

const isMobile = window.innerWidth < 991
const isTablet = window.innerWidth < 768


export const KastListing = ({ data }) => {

    // const imageArray = ["https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?cs=srgb&dl=pexels-vecislavas-popa-1571460.jpg&fm=jpg", "https://static.asianpaints.com/content/dam/asianpaintsbeautifulhomes/home-decor-advice/design-and-style/nine-small-house-interior-design-hacks/two-seater-couches-and-wall-mounts-design-hack.jpg", "https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZSUyMGludGVyaW9yfGVufDB8fDB8fHww&w=1000&q=80"]
    const imageArray = data?.image?.split(",") ?? []
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const [isLoading, setIsLoading] = React.useState(false);

    const [profileDetails, setProfileDetails] = React.useState([]);
    const [showAminity, setShowAminity] = React.useState(false);

    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")

    const [success, setSuccess] = React.useState("")
    const [error, setError] = React.useState("")


    const [severity, setSeverity] = React.useState("")

    const [snackMsg, setSnackMsg] = React.useState("")
    const [open, setOpen] = React.useState(true);

    React.useEffect(() => {
        setSuccess("")
        setError("")
    }, [name, email, phone])


    const addLead = async () => {
        setIsLoading(true)
        const listing_id = await getSourceNameformListingAPI(data?.id);
        if (listing_id?.data?.code == 200) {
            const datas = {
                type: "B",
                lead_type: "C",
                first_name: name,
                last_name: ".",
                mobile_1: phone,
                mobile_2: "",
                mobile_3: "",
                email: email,
                email_2: "",
                address_1: "",
                address_2: "",
                full_address: "-",
                city: "",
                state: "",
                zip_code: "",
                source_id: listing_id?.data?.data?.id,
                agent_id: "",
                listing_agent: "",
                company_attorney: "",
                loan_officer: "",
                insurance_agent: "",
                label: "label",
                notes: "",
                price_range: "",
                target_city: "",
                closing_date: "",
                bedroom: "",
                bathroom: "",
                garage: "",
                custom_tag: "",
                nurture: "",
                tag: "",
                summary: "",
                openfield1: "",
                openfield2: "",
                openfield3: "",
                openfield4: "",
                openfield5: "",
                openfield6: "",
                openfield7: "",
                openfield8: "",
                openfield9: "",
                openfield10: "",
                is_active: "Y",
                is_arch: "N",
                is_verified: "N",
                created_by: data?.created_by
            }

            CreateLeadWithoutTokenService(datas)
                .then((res) => {
                    // setSuccess("Your Details Saved Successfully.")
                    setSeverity("success")
                    setSnackMsg("Your Details Saved Successfully.")
                    setOpen(true)
                    setTimeout(() => {
                        setOpen(false)
                    }, 3000);
                    setName("")
                    setPhone("")
                    setEmail("")
                    setIsLoading(false)

                })
                .catch((err) => {
                    setSeverity("error")
                    setSnackMsg("Something went wrong. Please try again.")
                    setOpen(true)
                    setIsLoading(false)
                    setTimeout(() => {
                        setOpen(false)
                    }, 3000);
                    setName("")
                    setPhone("")
                    setEmail("")
                    // setError("")
                });
        }
        setIsLoading(false)

    }


    useEffect(() => {
        getUserDetails()
    }, [data?.created_by])

    const getUserDetails = async () => {
        const respo = await getProfileById(data?.created_by);
        console.log(respo.data.profile)
        setProfileDetails(respo.data.profile)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Update the current image index to display the next image
            setCurrentImageIndex((prevIndex) =>
                prevIndex === imageArray.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change image every 3 seconds (3000 milliseconds)

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []); // The empty dependency array ensures this effect runs only once after mount


    const validateAndSave = async () => {
        if (name != "" && email != "" && phone != "") {
            if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
                if (phone.length == 10) {
                    if (/^\d{10}$/.test(phone)) {
                        await addLead()
                    } else {
                        setError("Your Phone number is not valid please check and retry.")
                    }
                } else {
                    setError("Your phone number should be 10 digit long.");
                }
            } else {
                setError("Your email is not corrent. please enter correct format email")
            }

        } else {
            setError("Please enter details to continue.")
        }

    }


    return <Box sx={{ flex: 1, backgroundColor: "rgba(1, 5, 23, 0.8)" }}>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} action={
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    x
                </IconButton>
            </React.Fragment>
        } autoHideDuration={6000} sx={{ zIndex: 100 }}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                {snackMsg}
            </Alert>
        </Snackbar>
        <style>
            {`
          * {
            font-family: "Poppins" !important;
          }
          `}
        </style>

        {data?.created_by ? null :
            <Box style={{
                flex: 1, display: "flex", justifyContent: "center", justifyContent: "center", height: "100vh"
            }}>
                <Typography fontSize={20} mt={.5} fontFamily={"Poppins"} alignSelf={"center"} color={"#fff"} ><CircularProgress /></Typography>
            </Box>
        }
        {data?.created_by ?
            <Box sx={{ flex: 1 }}>
                <Grid container sx={{ mt: 5, }}>
                    <Grid container xs={12} style={{ alignSelf: "center", flex: 1 }}>
                        <Grid xs={0} sm={0} lg={2} md={1} />
                        <Grid xs={12} sm={12} lg={8} md={10} sx={{ flex: 1, p: 1 }}>
                            <div style={{ padding: 10 }}>
                                <ImageCrousel imageArray={imageArray} reset={() => { setCurrentImageIndex(0) }} next={() => {
                                    setCurrentImageIndex((prevIndex) =>
                                        prevIndex === imageArray.length - 1 ? 0 : prevIndex + 1
                                    );
                                }} previous={() => {
                                    setCurrentImageIndex((prevIndex) =>
                                        prevIndex === imageArray.length - 1 ? prevIndex - 1 : 0
                                    );
                                }} image={imageArray[currentImageIndex]} />


                                <div style={{ padding: 5, background: "rgba(0,0,0,0)", marginTop: -30 }}>

                                    <div style={{ background: "rgba(0,0,0,0)", flex: 1, display: "flex", flexDirection: "row", alignSelf: "flex-end", justifyContent: "flex-end", }}>

                                        {imageArray.map((item, index) => {
                                            return <>

                                                <div key={index} style={{ height: 10, width: 10, borderRadius: 10, background: imageArray[currentImageIndex] === item ? "#729AFF" : "#dddddd", margin: 2 }} />
                                            </>
                                        })}

                                    </div>
                                </div>
                                <Box sx={{ background: "linear-gradient(to right, #0B172E, #344C7E)", p: 2 }}>

                                    {data?.just_sold == "Y" ? <Box
                                        top="10px"
                                        left="-20px"
                                        width="120px"
                                        height="30px"
                                        bgcolor="red"
                                        color="white"
                                        fontWeight="bold"
                                        textAlign="center"
                                        transform="rotate(-45deg)"
                                        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                                        borderRadius={20}
                                        padding={0.5}
                                    >
                                        JUST SOLD
                                    </Box> :
                                        <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 22} fontWeight={isMobile ? "100" : "300"} fontFamily={"Poppins"} color={"#fff"} >{data?.status}</Typography>}
                                    <Typography fontSize={isMobile ? 16 : isTablet ? 18 : 32} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >{data?.listing_title}</Typography>
                                    <Typography fontSize={isMobile ? 10 : isTablet ? 14 : 20} mt={.5} fontFamily={"Poppins"} color={"#fff"} >{"$" + parseInt(data?.sales_prices).toLocaleString("en-US")}</Typography>
                                    <Typography fontSize={isMobile ? 10 : isTablet ? 14 : 20} mt={.5} fontWeight={"600"} fontFamily={"Poppins"} color={"#fff"} >{`${data?.bedrooms} bed  |  ${data.bathroom} bath  |  ${parseInt(data?.living_space).toLocaleString("en-US")} Sq.Ft. `}</Typography>
                                    {data?.year_built == null ? null :
                                        <Typography fontSize={isMobile ? 10 : isTablet ? 14 : 20} mt={.5} fontWeight={"200"} fontFamily={"Poppins"} color={"#fff"} >{"Year built: " + (data?.year_built == null ? "-" : data?.year_built)}</Typography>
                                    }
                                    {moment(data?.date, 'MM/DD/YYYY').isAfter(moment()) ? <div style={{ padding: 2 }}>
                                        <Grid xs={12} lg={9} md={9}>
                                            {
                                                data.just_sold == "Y" ? null :
                                                    <OpenHouse data={data} />
                                            }
                                        </Grid>
                                    </div> : null}

                                    <ProfileCard data={profileDetails[0] ?? {}} />
                                    <div style={{ background: "rgba(255,255,255,0.1)", borderRadius: 10, padding: 5, marginTop: 20 }}>
                                        <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 20} fontWeight={"600"} fontFamily={"Poppins"} color={"#fff"} sx={{ p: 2, textAlign: "justify" }} >{data?.description}</Typography>
                                    </div>


                                    {data?.just_sold == "Y" ?
                                        <>
                                            {data?.commentary != "" || data?.commentary != null ?
                                                <div style={{ background: "rgba(255,255,255,0.1)", borderRadius: 10, padding: 5, marginTop: 20 }}>
                                                    <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 20} fontWeight={"600"} fontFamily={"Poppins"} color={"#fff"} sx={{ p: 2, textAlign: "justify" }} >{data?.commentary}</Typography>
                                                </div> : null
                                            }
                                            {data?.testimonial != "" || data?.testimonial != null ?
                                                <div style={{ background: "rgba(255,255,255,0.1)", borderRadius: 10, padding: 5, marginTop: 20 }}>
                                                    <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 20} fontWeight={"600"} fontFamily={"Poppins"} color={"#fff"} sx={{ p: 2, textAlign: "justify" }} >{`" ${data?.testimonial} "`}</Typography>
                                                </div> : null}
                                        </> :
                                        <></>
                                    }



                                    {data?.other_amenities != null && data?.other_amenities != "" ? <Box sx={{ borderColor: "#729AFF", p: 2, my: 2, }} border={2} borderRadius={5}>
                                        <Typography color={"#ffffff"} fontSize={24} fontWeight={"bold"} fontFamily={"Poppins"}>{"Amenities"}</Typography>
                                        <Grid container spacing={1} m={1} sx={{ transition: "all 2s", }}>
                                            {data?.other_amenities?.split(",").map((item) => {
                                                return <Grid key={item} item xs={12} lg={6} sx={{ flexDirection: "row", display: "flex" }}>
                                                    <div style={{ padding: 5 }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><path fill="white" stroke="white" stroke-width="4" d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z" /></svg>
                                                    </div>
                                                    <Typography fontSize={isMobile ? 10 : isTablet ? 12 : 18} mt={.5} fontWeight={"400"} fontFamily={"Poppins"} color={"#fff"} >{item}</Typography></Grid>
                                            })}
                                        </Grid>


                                    </Box> : null}


                                    <div style={{ padding: 2 }}>
                                        <Grid lg={12}>
                                            <QRCodeBox data={data} />
                                        </Grid>
                                    </div>
                                    <RequestFrom data={data} name={name} isLoading={isLoading} phone={phone} email={email} setName={setName} setEmail={setEmail} setPhone={setPhone} validateAndSave={() => validateAndSave()} />
                                    {success != "" && <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 20} fontWeight={"600"} fontFamily={"Poppins"} color={"#fff"} sx={{ p: 2, textAlign: "justify" }} >{success}</Typography>}
                                    {error != "" && <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 20} fontWeight={"600"} fontFamily={"Poppins"} color={"#ff0000"} sx={{ p: 2, textAlign: "justify" }} >{error}</Typography>}

                                </Box>
                            </div>
                        </Grid>
                        <Grid xs={0} sm={0} lg={2} md={1} />
                    </Grid>
                </Grid>
            </Box> : null}

    </Box >


}


const RequestFrom = ({ data, setName, setPhone, setEmail, validateAndSave, name, email, phone, isLoading }) => {
    return <div style={{ marginTop: 20 }}>

        {
            //
            data.just_sold == "Y" ? <Typography fontSize={isMobile ? 16 : isTablet ? 16 : 24} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} sx={{ p: 1 }} >{" Want to know what your home is worth?"}</Typography> :

                <Typography fontSize={isMobile ? 16 : isTablet ? 16 : 24} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} sx={{ p: 1 }} >{data.holding_open_house == "1" ? "Open House Information" : "Request Information"}</Typography>
        }
        <Grid container spacing={2} sx={{ flex: 1, display: "flex", p: 2 }}>
            <Grid item xs={12}>
                <TextField onChange={(e) => { setName(e.target.value) }} sx={{ background: "rgba(0,0,0,0)", }}
                    value={name}
                    InputProps={{
                        style: {
                            borderRadius: '5px', // Set the border radius here
                            background: "rgba(0,0,0,0)",
                            color: "#fff",
                            border: "0.5px solid #d1d1d1",
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.94" d="M14.1114 12.6875C13.7429 11.8148 13.2083 11.0221 12.5372 10.3535C11.8681 9.68303 11.0755 9.14845 10.2032 8.7793C10.1954 8.77539 10.1875 8.77344 10.1797 8.76953C11.3965 7.89062 12.1875 6.45898 12.1875 4.84375C12.1875 2.16797 10.0196 0 7.3438 0C4.66802 0 2.50005 2.16797 2.50005 4.84375C2.50005 6.45898 3.29106 7.89062 4.50786 8.77148C4.50005 8.77539 4.49224 8.77734 4.48442 8.78125C3.60942 9.15039 2.82427 9.67969 2.15044 10.3555C1.47995 11.0245 0.94537 11.8171 0.576221 12.6895C0.213569 13.5435 0.0179823 14.4591 4.88398e-05 15.3867C-0.000472459 15.4076 0.00318384 15.4283 0.0108024 15.4477C0.018421 15.4671 0.0298476 15.4848 0.044409 15.4997C0.0589704 15.5147 0.0763719 15.5265 0.0955882 15.5346C0.114805 15.5427 0.135447 15.5469 0.156299 15.5469H1.32817C1.41411 15.5469 1.48247 15.4785 1.48442 15.3945C1.52349 13.8867 2.12895 12.4746 3.19927 11.4043C4.30669 10.2969 5.77739 9.6875 7.3438 9.6875C8.9102 9.6875 10.3809 10.2969 11.4883 11.4043C12.5586 12.4746 13.1641 13.8867 13.2032 15.3945C13.2051 15.4805 13.2735 15.5469 13.3594 15.5469H14.5313C14.5522 15.5469 14.5728 15.5427 14.592 15.5346C14.6112 15.5265 14.6286 15.5147 14.6432 15.4997C14.6578 15.4848 14.6692 15.4671 14.6768 15.4477C14.6844 15.4283 14.6881 15.4076 14.6875 15.3867C14.668 14.4531 14.4747 13.5449 14.1114 12.6875ZM7.3438 8.20312C6.44731 8.20312 5.60356 7.85352 4.9688 7.21875C4.33403 6.58398 3.98442 5.74023 3.98442 4.84375C3.98442 3.94727 4.33403 3.10352 4.9688 2.46875C5.60356 1.83398 6.44731 1.48438 7.3438 1.48438C8.24028 1.48438 9.08403 1.83398 9.7188 2.46875C10.3536 3.10352 10.7032 3.94727 10.7032 4.84375C10.7032 5.74023 10.3536 6.58398 9.7188 7.21875C9.08403 7.85352 8.24028 8.20312 7.3438 8.20312Z" fill="#729AFF" />
                                    </svg>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} placeholder='Enter full name' />
            </Grid>

            <Grid item xs={12}>
                <TextField value={email} onChange={(e) => { setEmail(e.target.value) }} sx={{ background: "rgba(0,0,0,0)", }}
                    InputProps={{
                        style: {
                            borderRadius: '5px', // Set the border radius here
                            background: "rgba(0,0,0,0)",
                            color: "#fff",
                            border: "0.5px solid #d1d1d1",
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.94" d="M16.6667 1.66667C16.6667 0.75 15.9167 0 15 0H1.66667C0.75 0 0 0.75 0 1.66667V11.6667C0 12.5833 0.75 13.3333 1.66667 13.3333H15C15.9167 13.3333 16.6667 12.5833 16.6667 11.6667V1.66667ZM15 1.66667L8.33333 5.83333L1.66667 1.66667H15ZM15 11.6667H1.66667V3.33333L8.33333 7.5L15 3.33333V11.6667Z" fill="#729AFF" />
                                    </svg>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} placeholder='Enter email' />
            </Grid>
            <Grid item xs={12}>
                <TextField value={phone} onChange={(e) => { setPhone(e.target.value) }} sx={{ background: "rgba(0,0,0,0)", }}
                    InputProps={{
                        style: {
                            borderRadius: '5px', // Set the border radius here
                            background: "rgba(0,0,0,0)",
                            color: "#fff",
                            border: "0.5px solid #d1d1d1",
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#729AFF" d="M20 15.5c-1.2 0-2.5-.2-3.6-.6h-.3c-.3 0-.5.1-.7.3l-2.2 2.2c-2.8-1.5-5.2-3.8-6.6-6.6l2.2-2.2c.3-.3.4-.7.2-1c-.3-1.1-.5-2.4-.5-3.6c0-.5-.5-1-1-1H4c-.5 0-1 .5-1 1c0 9.4 7.6 17 17 17c.5 0 1-.5 1-1v-3.5c0-.5-.5-1-1-1M5 5h1.5c.1.9.3 1.8.5 2.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5m14 14c-1.3-.1-2.6-.4-3.8-.8l1.2-1.2c.8.2 1.7.4 2.6.4z" /></svg>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} placeholder='Enter Mobile Number' />
            </Grid>
            <Grid item xs={12} my={1}>
                <Button onClick={() => { validateAndSave() }} sx={{ background: "#436EDD", color: "#fff", fontSize: '12px', px: 10, height: 50, width: isMobile ? "100%" : "auto" }} fontSize={10} color='primary'>{isLoading ? <CircularProgress size={20} /> : "Submit"}</Button>
            </Grid>
        </Grid>

    </div>
}


const OpenHouse = ({ data }) => {



    return <Box sx={{ borderColor: "#729AFF", p: 2, my: 2 }} border={2} borderRadius={5}>
        {data.holding_open_house == "1" ?
            <Typography fontSize={isMobile ? 14 : isTablet ? 16 : 24} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} padding={1} >{"Open House"}</Typography>
            : null}
        {data.holding_open_house == "1" ?
            <div style={{ flexDirection: "row", alignSelf: "center", flex: 1, display: "flex", padding: 2, }}>
                <svg width={isMobile ? "24" : "40"} height={isMobile ? "24" : "40"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.88">
                        <path d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C6.47 22 2 17.5 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2ZM12.5 7V12.25L17 14.92L16.25 16.15L11 13V7H12.5Z" fill="#729AFF" />
                    </g>
                </svg>
                <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >
                    {moment(data?.date, 'DD/MM/YYYY').format("LL") + " | " + data?.time_fram}</Typography>

            </div> : null
        }
        <div style={{ flexDirection: "row", alignSelf: "center", flex: 1, display: "flex", padding: 2 }}>
            <svg width={isMobile ? "24" : "40"} height={isMobile ? "24" : "40"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.88">
                    <path d="M12 2C7.6 2 4 5.6 4 10C4 15.4 11 21.5 11.3 21.8C11.5 21.9 11.8 22 12 22C12.2 22 12.5 21.9 12.7 21.8C13 21.5 20 15.4 20 10C20 5.6 16.4 2 12 2ZM12 19.7C9.9 17.7 6 13.4 6 10C6 6.7 8.7 4 12 4C15.3 4 18 6.7 18 10C18 13.3 14.1 17.7 12 19.7ZM12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6ZM12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z" fill="#729AFF" />
                </g>
            </svg>

            <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >
                {data?.address}</Typography>

        </div>

    </Box>
}

const QRCodeBox = ({ data }) => {
    const chCount = isMobile ? 20 : 35;
    return <Box sx={{ borderColor: "#729AFF", p: 2, my: 2, }} border={2} borderRadius={5}>
        <Grid container sx={{ flex: 1 }}>
            <Grid item lg={10} xs={8}>
                <Typography fontSize={isMobile ? 14 : isTablet ? 16 : 24} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} padding={1} >{"Listing link"}</Typography>
                <div style={{ flexDirection: "row", alignSelf: "center", flex: 1, display: "flex", padding: 2 }}>
                    <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1, color: "#729aFF" }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >
                        {window.location.length > chCount ? window.location : window.location.toString().substr(0, chCount) + "..."}</Typography>
                </div>
            </Grid>
            <Grid lg={2} item xs={4} sx={{ alignSelf: "flex-end", justifyContent: "flex-end", flex: 1, textAlign: "right" }}>
                <QRCode size={isMobile ? 50 : 100} value={window.location.href} backgroundColor="rbga(0,0,0,0)" />
            </Grid>
        </Grid>
    </Box>
}


const ProfileCard = ({ data }) => {
    return <div style={{ background: "rgba(255,255,255,0.1)", borderRadius: 10, padding: 5, marginTop: 10, }}>
        <Grid justifyContent={"flex-end"} xs={12} container>
            <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >{data.company_name}</Typography>
        </Grid>
        <Grid container sx={{ mb: 2 }}>
            <Grid item xs={4} sx={{ flex: 1, display: "flex", alignSelf: "center", justifyContent: "center" }}>
                <img src={data.profile_photo ?? ""} style={{ borderRadius: 200, height: isMobile ? 80 : 200, width: isMobile ? 80 : 200, }} />
            </Grid>
            <Grid item xs={8} alignSelf={"center"}>
                <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >{data.full_name}</Typography>
                <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >{data.job_title}</Typography>
                <Typography onClick={() => { window.location.href = data.website }} fontSize={isMobile ? 12 : isTablet ? 14 : 28} sx={{ p: 0.3, mx: 1 }} style={{ textDecorationLine: "underline" }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"} >{"Visit Website"}</Typography>
            </Grid>
        </Grid>
        <Grid container sx={{ mb: 2 }} justifyContent={"center"}>
            <Grid lg={6} sx={{ flexDirection: "row", display: "flex" }} justifyContent={"center"}>
                <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "1.25em" : "2em"} height={isMobile ? "1.25em" : "2.5em"} viewBox="0 0 24 24"><path fill="white" d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z" /></svg>
                <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 24} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"}>{data.email}</Typography>
            </Grid>
            {/* 
            <Grid lg={6} sx={{ flexDirection: "row", display: "flex" }} justifyContent={"center"}>
                <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "1em" : "2em"} height={isMobile ? "1.25em" : "2.5em"} viewBox="0 0 24 24"><path fill="white" d="m19.23 15.26l-2.54-.29a1.99 1.99 0 0 0-1.64.57l-1.84 1.84a15.045 15.045 0 0 1-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52a2.001 2.001 0 0 0-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07c.53 8.54 7.36 15.36 15.89 15.89c1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98" /></svg>
                <Typography fontSize={isMobile ? 12 : isTablet ? 14 : 24} sx={{ p: 0.3, mx: 1 }} fontWeight={"bold"} fontFamily={"Poppins"} color={"#fff"}>{data.mobile_no}</Typography>
            </Grid> */}

        </Grid>
    </div >
}



const ImageCrousel = ({ image, next, previous, reset, imageArray }) => {
    if (image === undefined) {
        reset()
    }
    return <Grid container sx={{}}>
        <Box id="bgListing" sx={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, height: isMobile ? "200px" : "500px", width: "100%", flex: 1, display: "flex", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", justifyContent: "center", backgroundImage: `url(${image})` }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignSelf: "center", }}>
                <div onClick={previous} style={{ transform: "rotate(180deg)", cursor: "pointer" }}>
                    <Courselbutton />
                </div>

                <div onClick={next} style={{ transform: "translateY(8px)", cursor: "pointer" }}>
                    <Courselbutton />
                </div>
            </div>
            <div>
            </div>
        </Box>
        {/* <img src={image} style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, height: isMobile ? "200px" : "500px", width: "100%", flex: 1 }} /> */}
    </Grid >
}

const Courselbutton = () => {
    return <svg width="57" height="57" viewBox="0 0 55 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#141E34" fill-opacity="0.4" />
        <g opacity="0.9">
            <path d="M23.965 36.9353C23.826 36.7966 23.7157 36.6317 23.6404 36.4503C23.5651 36.2688 23.5264 36.0743 23.5264 35.8778C23.5264 35.6814 23.5651 35.4869 23.6404 35.3054C23.7157 35.1239 23.826 34.9591 23.965 34.8203L29.785 29.0003L23.965 23.1803C23.6846 22.8999 23.527 22.5195 23.527 22.1228C23.527 21.7262 23.6846 21.3458 23.965 21.0653C24.2455 20.7849 24.6259 20.6273 25.0225 20.6273C25.4192 20.6273 25.7996 20.7849 26.08 21.0653L32.965 27.9503C33.1041 28.0891 33.2144 28.2539 33.2897 28.4354C33.365 28.6169 33.4037 28.8114 33.4037 29.0078C33.4037 29.2043 33.365 29.3988 33.2897 29.5803C33.2144 29.7617 33.1041 29.9266 32.965 30.0653L26.08 36.9503C25.51 37.5203 24.55 37.5203 23.965 36.9353Z" fill="white" />
        </g>
    </svg>

}


